.carousel :global .carousel__dot {
  @apply text-2xl;
  @apply p-1;
}
.carousel :global .carousel__dot::before {
  content: "●";
  text-shadow: 0 0 10px black;
}
.carousel :global .carousel__dot--selected::before {
  content: "⦿";
  font-size: 2rem;
}

.intersectionContainer {
  height: 70vh;
}

.intersectionContainer:global(.noo + div) {
  @apply hidden;
}
